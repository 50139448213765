/* eslint-disable max-lines */
import { put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import type { Task } from '@redux-saga/types';
import { CancelledEffect } from '@redux-saga/core/effects';

import { GeneralResponse, SubscriptionCancelResponse } from 'api/types/response';
import api from 'api';

import {
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    cancelSubscription,
    fetchSubscriptions,
    fetchDiscountSubscriptions,
    setIsDiscountLoading,
    setDiscountSubscription,
    updateSubscription as updateSubscriptionAction,
    setReminder as setReminderAction,
    pauseSubscription as pauseSubscriptionAction,
    resumeSubscription as resumeSubscriptionAction,
    resetSubscription as resetSubscriptionAction,
    restoreSubscription as restoreSubscriptionAction,
    setFreeMonthSubscription as setFreeMonthSubscriptionAction,
} from './actions';
import { notifyError } from '../notifications/actions';

import * as actionTypes from './actionTypes';

import { DEFAULT_CANCEL_SUBSCRIPTION_ERROR } from 'constants/subscriptions';

import { IDiscountSubscription, ISubscription, ISubscriptionResponse } from 'types/subscription';
import { ICancelSubscription } from './types';

function* getSubscription() {
    try {
        const response: GeneralResponse<ISubscriptionResponse> = yield call(api.subscriptions.getSubscriptions);
        const subscriptions: ISubscription[] = response.data.solid_sub;

        const filteredSubscriptions = subscriptions.filter((subscription) => subscription.status !== 'pending_failed');

        yield put(setSubscriptions(filteredSubscriptions));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);

        yield take(actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        // @ts-ignore
        payload.onError(error);
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    // yield call(sendAnalyticSubscriptionCancelTriggerRequest);

    try {
        // yield delay(3000);

        const generalResponse: GeneralResponse<SubscriptionCancelResponse> = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });
        const response = generalResponse.data;

        if (response.status !== 'ok') {
            throw new Error(DEFAULT_CANCEL_SUBSCRIPTION_ERROR);
        }

        yield put(fetchSubscriptions());
        payload.onSuccess();
    } catch (error: any) {
        payload.onError(error);
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            payload.onCancel && payload.onCancel();
        }
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscriptions>) {
    try {
        yield put(setIsDiscountLoading(true));

        const response: GeneralResponse<IDiscountSubscription> = yield call(
            api.subscriptions.getDiscountSubscriptions,
            payload
        );

        yield put(setDiscountSubscription(response.data));
        yield put(setIsDiscountLoading(false));
    } catch (error) {
        yield put(setIsDiscountLoading(false));
    }
}

function* updateSubscription({ payload }: ReturnType<typeof updateSubscriptionAction>) {
    const { updatedSubscription, onSuccess, onError } = payload;

    try {
        yield put(setIsDiscountLoading(true));

        yield call(api.subscriptions.updateSubscription, updatedSubscription);

        yield put(setIsDiscountLoading(false));

        yield call(onSuccess);
    } catch (error) {
        yield call(onError);

        yield put(setIsDiscountLoading(false));
    }
}

function* setReminder({ payload }: ReturnType<typeof setReminderAction>) {
    const { externalId, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.setReminder, { external_id: externalId });

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError);
    }
}

function* pauseSubscription({ payload }: ReturnType<typeof pauseSubscriptionAction>) {
    const { external_id, days, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.pauseSubscription, {
            external_id,
            days,
        });

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError, error as string);
    }
}

function* setFreeMonthSubscription({ payload }: ReturnType<typeof setFreeMonthSubscriptionAction>) {
    const { external_id, days, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.setFreeMonthSubscription, {
            external_id,
            days,
        });

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError, error as string);
    }
}

function* resumeSubscription({ payload }: ReturnType<typeof resumeSubscriptionAction>) {
    const { onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.resumeSubscription);

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError, error as string);
    }
}

function* resetSubscription({ payload }: ReturnType<typeof resetSubscriptionAction>) {
    const { product, external_id, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.resetSubscription, {
            external_id,
            product,
        });

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError);
    }
}

function* restoreSubscription({ payload }: ReturnType<typeof restoreSubscriptionAction>) {
    const { external_id, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.restoreSubscription, {
            external_id,
        });

        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError);
    }
}

export const subscriptionsTestData = {
    getSubscription,
    makeSubscriptionCancelling,
    callUnsubscribe,
    getDiscountSubscription,
    updateSubscription,
    setReminder,
    pauseSubscription,
    resumeSubscription,
    restoreSubscription,
};

export default function* watchSubscriptions() {
    yield all([
        takeLatest(actionTypes.FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(actionTypes.CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(actionTypes.FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(actionTypes.UPDATE_SUBSCRIPTION, updateSubscription),
        takeLatest(actionTypes.SET_REMINDER, setReminder),
        takeLatest(actionTypes.PAUSE_SUBSCRIPTION, pauseSubscription),
        takeLatest(actionTypes.SET_FREE_MONTH_SUBSCRIPTION, setFreeMonthSubscription),
        takeLatest(actionTypes.RESUME_SUBSCRIPTION, resumeSubscription),
        takeLatest(actionTypes.RESET_SUBSCRIPTION, resetSubscription),
        takeLatest(actionTypes.RESTORE_SUBSCRIPTION, restoreSubscription),
    ]);
}
