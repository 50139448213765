import { ModalName } from 'store/modals/types';

export const VIP_SUPPORT = 'vip_support';
export const SPECIFIC_REASONS = [
    {
        reasons: ['technical_issue', 'didnt_know_autorenewal', 'not_what_expected', 'purchased_by_mistake', 'other'],
        modal: ModalName.premiumFeedbackModal,
    },
    {
        reasons: ['expensive'],
        modal: ModalName.tooExpensiveModalModal,
    },
    {
        reasons: ['no_longer_need'],
        modal: ModalName.noLongerNeedModal,
    },
];

export const SPECIFIC_REASONS_SIMPLIFIED = [
    {
        reasons: [
            'technical_issue',
            'didnt_know_autorenewal',
            'not_what_expected',
            'purchased_by_mistake',
            'other',
            'expensive',
            'no_longer_need',
        ],
        modal: ModalName.simplifiedFeedbackModal,
    },
];
