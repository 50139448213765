import { useTranslation } from 'react-i18next';

export const getConfig = (reason: string, isCanAddPremium: boolean) => {
    const { t } = useTranslation();

    const getReasonConfig = (reason: string) => {
        switch (reason) {
            case 'technical_issue': {
                const subtitle = isCanAddPremium
                    ? t('subscription.cancellation.technicalIssue.premium.subtitle')
                    : t('subscription.cancellation.technicalIssue.withoutPremium.subtitle');

                return {
                    title: t('subscription.cancellation.technicalIssue.title'),
                    subtitle,
                    placeholder: t('subscription.cancelFlow.feedbackModal.technicalIssue.textarea.placeholder'),
                };
            }

            case 'didnt_know_autorenewal': {
                const subtitle = isCanAddPremium
                    ? t('subscription.cancellation.didntKnowAutorenewal.premium.subtitle')
                    : t('subscription.cancellation.didntKnowAutorenewal.withoutPremium.subtitle');

                return {
                    title: t('subscription.cancellation.didntKnowAutorenewal.title'),
                    subtitle,
                    placeholder: t('subscription.cancelFlow.feedbackModal.textarea.placeholder'),
                };
            }

            case 'not_what_expected': {
                const subtitle = isCanAddPremium
                    ? t('subscription.cancellation.notWhatExpected.premium.subtitle')
                    : t('subscription.cancellation.notWhatExpected.withoutPremium.subtitle');

                return {
                    title: t('subscription.cancellation.notWhatExpected.title'),
                    subtitle,
                };
            }

            case 'expensive': {
                return {
                    title: t('subscription.cancellation.expensive.withoutPremium.title'),
                    subtitle: t('subscription.cancellation.expensive.withoutPremium.subtitle'),
                    placeholder: t('subscription.cancelFlow.feedbackModal.textarea.placeholder'),
                };
            }

            case 'no_longer_need': {
                return {
                    title: t('subscription.cancellation.noLongerNeed.withoutPremium.title'),
                    subtitle: t('subscription.cancellation.noLongerNeed.withoutPremium.subtitle'),
                    placeholder: t('subscription.cancelFlow.feedbackModal.textarea.placeholder'),
                };
            }

            case 'purchased_by_mistake': {
                const subtitle = isCanAddPremium
                    ? t('subscription.cancellation.purchasedByMistake.premium.subtitle')
                    : t('subscription.cancellation.purchasedByMistake.withoutPremium.subtitle');

                return {
                    title: t('subscription.cancellation.purchasedByMistake.title'),
                    subtitle,
                };
            }

            case 'other': {
                const subtitle = isCanAddPremium
                    ? t('subscription.cancellation.other.premium.subtitle')
                    : t('subscription.cancellation.other.withoutPremium.subtitle');

                return {
                    title: t('subscription.cancellation.other.title'),
                    subtitle,
                };
            }

            default:
                return {
                    title: t('subscription.cancelFlow.feedbackModal.title'),
                    subtitle: t('subscription.cancelFlow.feedbackModal.subtitle'),
                };
        }
    };

    return {
        placeholder: t('subscription.cancelFlow.feedbackModal.textarea.placeholder'),
        buttons: {
            sendFeedbackBtnText: t('basics.continue'),
            cancelBtnText: t('subscription.text.cancel'),
        },
        disclaimer: t('subscription.cancellation.feedback.disclaimer2'),
        ...getReasonConfig(reason),
    };
};
