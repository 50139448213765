import { selectSubscriptions } from 'store/subscriptions/selectors';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticSendFeedbackClick } from 'services/analytics';

import { useSelector, useCancelSubscription } from 'hooks';

import PremiumFeedbackModal from 'components/ModalContainers/PremiumFeedbackModalContainer/components/PremiumFeedbackModal';

const SimplifiedFeedbackModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription } = useCancelSubscription();

    if (!cancellationCandidate) {
        return null;
    }

    const eventName = 'cancel_feedback';

    useScreenLoad(eventName);

    const cancelSubscriptionHandler = () => {
        onCancelSubscription();
    };

    const sendFeedbackHandler = (value: string) => {
        sendAnalyticSendFeedbackClick(eventName, value);

        onCancelSubscription();
    };

    return (
        <PremiumFeedbackModal
            reason="purchased_by_mistake"
            isCanAddPremium={false}
            onCancelSubscription={cancelSubscriptionHandler}
            onSendFeedback={sendFeedbackHandler}
        />
    );
};

export default SimplifiedFeedbackModalContainer;
