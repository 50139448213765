export const AVAILABLE_LOCALIZATIONS = ['de', 'en', 'es', 'fr', 'ja', 'pt', 'zh', 'it'];
export const AVAILABLE_LOCALIZATIONS_WITH_COUNTRY = [
    'de-DE',
    'en-US',
    'es-ES',
    'fr-FR',
    'ja-JP',
    'pt-PT',
    'zh-CH',
    'zh-TW',
    'it-IT',
];

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_NAVIGATOR_LANGUAGE = 'en-US';
export const DEFAULT_COUNTRY = 'US';
export const CHINESE_LANGUAGE = 'zh';
