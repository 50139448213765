import {
    AVAILABLE_LOCALIZATIONS,
    CHINESE_LANGUAGE,
    AVAILABLE_LOCALIZATIONS_WITH_COUNTRY,
    DEFAULT_NAVIGATOR_LANGUAGE,
} from 'constants/localization';

export const getNavigatorLanguage = (withCountry?: boolean) => {
    let language;

    if (navigator?.languages?.length) {
        language = navigator.languages[0];
    } else {
        language = navigator?.language || DEFAULT_NAVIGATOR_LANGUAGE;
    }

    return withCountry ? language : language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage);

    return isLanguageLocalized ? navigatorLanguage : null;
};

export const getCurrentLocalization = () => {
    const routerLang = window.location.pathname.split('/')[1];

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(routerLang);

    return isLanguageLocalized ? routerLang : null;
};

export const getLanguageWithCountry = (lang: string): string | null => {
    if (lang === CHINESE_LANGUAGE) {
        const navigatorLanguage = getNavigatorLanguage(true);

        return AVAILABLE_LOCALIZATIONS_WITH_COUNTRY.includes(navigatorLanguage) ? navigatorLanguage : null;
    }

    return AVAILABLE_LOCALIZATIONS_WITH_COUNTRY.find((item) => item.includes(lang)) || null;
};
