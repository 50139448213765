import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import { SPECIFIC_REASONS_SIMPLIFIED, VIP_SUPPORT } from './constants';

import { useCancelSubscription, useModals, useSelector } from 'hooks';

const useCancelSubscriptionModals = () => {
    const { openModal } = useModals();
    const { discountSubscription } = useSelector(selectSubscriptions);
    const { onCancelSubscription, cancellationCandidate } = useCancelSubscription();

    const defaultCancelSubscriptionHandler = () => {
        openModal(ModalName.waitingModal);
        onCancelSubscription();
    };

    const specificCancelSubscriptionHandler = (reason: string) => {
        // Change on SPECIFIC_REASONS
        const specReason = SPECIFIC_REASONS_SIMPLIFIED.find((specReason) => specReason.reasons.includes(reason));

        if (specReason) {
            openModal(specReason.modal, {
                reason,
            });
        } else if (discountSubscription) {
            openModal(ModalName.specialOfferModal);
        } else {
            defaultCancelSubscriptionHandler();
        }
    };

    const cancelSubscriptionHandler = (reason: string) => {
        if (cancellationCandidate?.productCode === VIP_SUPPORT) {
            defaultCancelSubscriptionHandler();
        } else {
            specificCancelSubscriptionHandler(reason);
        }
    };

    return { cancelSubscriptionHandler };
};

export default useCancelSubscriptionModals;
